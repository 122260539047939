@layer components {
  .login-form {
    border: 1px solid var(--chakra-colors-gray-300);
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-block: calc(max(50vh - 10rem, 1rem));
    margin-inline: calc(max(50vw - 14rem, 1rem));
    padding: 1rem;
  }

  .login-form legend {
    font-size: var(--chakra-fontSizes-xl);
  }

  /* {TODO: extract to shared component CSS} */
  .login-form footer {
    display: flex;
    flex-direction: row-reverse;
    gap: 0.75rem;
  }
}
